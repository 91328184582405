<script>
import {formatPrice} from '@/filters.js'

export default {
  name: 'PlanDetails',
  props: ['plan', 'name_cot'],
  filters: {
    number_format: function (val) {
      return parseFloat(val).toFixed(2)
    }
  },
  methods: {
    formatPrice,
    async download() {
      let request = {
        finance_product_id: this.plan.finance.financial_product_id,
        currency: this.plan.finance.currency,
        rate: 0,
        loan_type: this.plan.finance.loan_type,
        total_cost: this.plan.finance.total_cost,
        term: this.plan.finance.term,
        delivery_term: this.plan.finance.delivery_term,
        surplus_percentage: this.plan.finance.total_cost_surplus_percentage,
        capital_paid_at_term: this.plan.finance.capital_paid_at_term_percentage,
        payment_periodicity: this.plan.finance.payment_periodicity,
        spread_portfolio: this.plan.finance.spread_portfolio,
        amortization_type: this.plan.finance.amortization_type,
        deposit_percentage: this.plan.finance.deposit_percentage,
        financed_interest_percentage: this.plan.finance.financed_interests_percentage,
      };

      const {data} = await axios.post('/api/loan/finance/pdf', request)
      const downloadLink = document.createElement("a");
      downloadLink.href = data.pdf_base64;
      downloadLink.download = this.name !== '' ? 'Simulación ' + this.name_cot + '.pdf' : 'Simulación.pdf'
      downloadLink.click();
    }
  }
}
</script>

<template>
  <vx-card title="Simulación" v-if="plan !== null">

    <div slot="no-body" class="pr-6 pl-6 pb-base">
      <p class="text-subtitle mt-2">Ahorros proyectados para fines informativos. Cálculos generados en función
        de cotización, consumo y precios actuales de electricidad. Condiciones de crédito sujetas a aprobación.</p>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap">
            <vs-button
                icon-pack="feather"
                icon="icon-download-cloud"
                class="mb-5 mt-5"
                @click="download()">
              Descargar
            </vs-button>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="title">Información de cotización.</div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">

          <!-- resumen del credito -->
          <table class="table-row full-border">
            <caption></caption>
            <tr class="vertical-top">
              <!-- COL 1-->
              <td class="w-50 p-l">
                <table class="table-row-spaced">
                  <tbody>
                  <tr class="info-row">
                    <td class="irl bold">Título de simulación</td>
                    <td class="irv">{{ name_cot !== '' ? name_cot : '-' }}</td>
                  </tr>
                  </tbody>
                </table>
                <table class="table-row-spaced">
                  <tbody>
                  <tr class="info-row">
                    <td class="irl bold">Costo del sistema</td>
                    <td class="irv">{{ formatPrice(plan.finance.total_cost) }}</td>
                  </tr>
                  </tbody>
                </table>
              </td>

              <!-- COL 2-->
              <td class="w-50 p-r">
                <!-- FREE SPACE -->
              </td>
            </tr>
          </table>
          <!-- fin resumen del credito -->
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <!-- resumen del credito -->
          <table class="table-row full-border">
            <tr class="vertical-top">
              <!-- COL 1-->
              <td class="w-50 p-l">
                <div class="title">El resumen de tu crédito.</div>
                <table class="table-row-spaced">
                  <tbody>
                  <tr class="info-row">
                    <td class="irl bold">Costo del Sistema - Enganche</td>
                    <td class="irv">${{ formatPrice(plan.finance.total_cost - plan.finance.deposit) }}</td>
                  </tr>
                  <tr class="info-row shaded">
                    <td class="irl bold">+ Interesés Financiados</td>
                    <td class="irv">${{ formatPrice(plan.finance.financed_interests) }}</td>
                  </tr>
                  <tr class="info-row dark">
                    <td class="irl bold">= Monto a financiar</td>
                    <td class="irv">${{ formatPrice(plan.finance.financed_amount) }}</td>
                  </tr>
                  <tr class="info-row">
                    <td class="irl bold">Plazo (Meses)</td>
                    <td class="irv">{{ plan.finance.term }}</td>
                  </tr>
                  <tr class="info-row">
                    <td class="irl bold">Tasa Anual</td>
                    <td class="irv">{{ plan.finance.annual_interest_percentage * 100|number_format }}%</td>
                  </tr>
                  </tbody>
                </table>
              </td>

              <!-- COL 2-->
              <td class="w-50 p-r">
                <div class="title">Tus pagos.</div>
                <table class="table-row-spaced">
                  <tbody>
                  <tr class="info-row">
                    <td class="irl bold">Comisión por gastos de pre-originación</td>
                    <td class="irv">${{ formatPrice(plan.finance.openning_comission) }}</td>
                  </tr>
                  <tr class="info-row" v-if="plan.finance.financial_product_id === 1 || plan.finance.financial_product_id === 2">
                    <td class="irl bold">+ Enganche ({{ plan.finance.deposit_percentage * 100 }}%)</td>
                    <td class="irv">${{ formatPrice(plan.finance.deposit) }}</td>
                  </tr>
                  <!--<tr class="info-row dark">
                    <td class="irl bold">= Pago Inicial <span v-if="plan.finance.delivery_term > 0">(1 Pago)</span></td>
                    <td class="irv">${{ formatPrice(plan.finance.initial_payment) }}</td>
                  </tr>-->
                  <tr class="info-row high" v-if="plan.finance.delivery_term > 0">
                    <td class="irl bold">Pago Entrega ({{ plan.finance.delivery_term }} Pagos)</td>
                    <td class="irv">${{ formatPrice(plan.finance.symbolic_payment) }}</td>
                  </tr>
                  <tr class="info-row high">
                    <td class="irl bold">Pago Mensual ({{ plan.finance.term - plan.finance.delivery_term }} Pagos)
                    </td>
                    <td class="irv">${{ formatPrice(plan.finance.monthly_payment) }}</td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
          <!-- fin resumen del credito -->
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col w-full">

          <!-- Tabla plan de pagos -->
          <table class="table-dynamic" aria-describedby="pagos">
            <thead>
            <tr class="table-title">
              <th colspan="8">
                <div class="title pb-3 text-left">Plan de Pagos.</div>
              </th>
            </tr>
            <tr class="table-cols">
              <th>Mes</th>
              <th>Pago</th>
              <template v-if="plan.finance.financed_interests > 0">
                <th>Pago en efectivo</th>
                <th>Pago Financiado</th>
              </template>
              <th>Saldo Inicial</th>
              <th>Amort. a Capital</th>
              <th>Interés</th>
              <th>Comisión</th>
              <th>IVA</th>
              <th>Saldo Final</th>
            </tr>
            </thead>
            <tbody>
            <tr class="f-12" v-for="amortizationItem in plan.amortization" :key="amortizationItem.paymentNumber">
              <td class="center">{{ amortizationItem.number }}</td>
              <td class="center">${{ formatPrice(amortizationItem.payment) }}</td>
              <template v-if="plan.finance.financed_interests > 0">
                <td class="center">${{ formatPrice(amortizationItem.cash_payment) }}</td>
                <td class="center">${{ formatPrice(amortizationItem.payment - amortizationItem.cash_payment) }}</td>
              </template>
              <td class="center">${{ formatPrice(amortizationItem.initial_debt) }}</td>
              <td class="center">${{ formatPrice(amortizationItem.amortization) }}</td>
              <td class="center">${{ formatPrice(amortizationItem.interest) }}</td>
              <td class="center">${{ formatPrice(amortizationItem.client_comission) }}</td>
              <td class="center">${{ formatPrice(amortizationItem.tax + amortizationItem.client_commission_tax) }}</td>
              <td class="center">${{ formatPrice(amortizationItem.final_debt) }}</td>
            </tr>
            </tbody>
          </table>
          <!-- Fin Tabla plan de pagos -->

        </div>
      </div>
    </div>
  </vx-card>
</template>

<style scoped>
.table-cols th {
  text-align: center;
}

.table-row {
  width: 100%;
  border-collapse: collapse;
  display: table !important;
}

.table-row-spaced {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 5px;
}

.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-38 {
  width: 38%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.p-l {
  padding-right: 10px;
}

.p-m {
  padding-left: 10px;
  padding-right: 10px;
}

.p-r {
  padding-left: 10px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.al-l {
  float: left;
}

.al-r {
  float: right;
}

.primary {
  color: #28DE18;
}

.secondary {
  color: #000;
}


.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.vertical-top {
  vertical-align: top;
}

.vertical-center {
  vertical-align: middle;
}

.row-space {
  height: 18px;
}

.col-space {
  height: 10px;
}

.word-space {
  height: 5px;
}

.title {
  font-size: 18px;
  font-weight: normal;
  font-family: "gilroybold";
}

.info-row {
  width: 100%;
  font-family: "gilroybold";
  /*  font-size: 12px; */
}

.info-row > td {
  height: 35px;
}

.info-row.high {
  font-weight: bold;
  color: #28DE18;
}

.info-row.shaded {
  background-color: #ccc;
  color: #000;
}

.info-row.dark {
  background-color: #313131;
  color: #fff;
}

.info-row.colored {
  font-weight: bold;
  background-color: #28DE18;
  color: #fff;
}

.irl {
  padding-left: 6px;
  text-align: left;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #e8e8e8;
  border-top-color: transparent;
}

.irv {
  padding-right: 6px;
  text-align: right;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #e8e8e8;
  border-top-color: transparent;
}

.full-border .irl {
  padding-left: 6px;
  text-align: left;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #000;
}

.full-border .irv {
  padding-right: 6px;
  text-align: right;
  border-width: 2px 2px 2px 0;
  border-style: solid;
  border-color: #000;
  border-top-color: #000;
}

.strong .irl {
  border-width: 1px 0 2px 0px;
  border-bottom-color: #000;
}

.strong .irv {
  border-width: 1px 0px 2px 0;
  border-bottom-color: #000;
}

.full-border .strong .irl {
  border-width: 1px 0 2px 1px;
  border-color: #000;
}

.full-border .strong .irv {
  border-width: 1px 1px 2px 0;
  border-color: #000;
}

.high .irl {
  border-width: 2px 0 2px 2px;
  border-color: #28DE18;
}

.high .irv {
  border-width: 2px 2px 2px 0;
  border-color: #28DE18;
}

.shaded .irl, .shaded .irv {
  border-color: #ccc;
}

.colored .irl, .colored .irv {
  border-color: #28DE18;
}

.dark .irl, .dark .irv {
  border-color: #313131;
}

.black {
  color: #000;
}

td.empty {
  font-size: 14px;
  text-align: center;
  background-color: #e8e8e8
}

.table-dynamic {
  width: 100%;
  border-collapse: collapse;
}

.table-dynamic .table-cols {
  background-color: #28DE18;
  font-weight: normal;
  font-family: 'gilroybold';
  color: #fff;
  text-align: center;
}

.table-dynamic .table-cols th {
  font-weight: bold;
  font-size: 14px;
  height: 30px;
  vertical-align: middle;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.table-dynamic .table-cols th:first-child {
  border-left: 1px solid #000;
}

.table-dynamic .table-cols th:last-child {
  border-right: 1px solid #000;
}

.table-dynamic > tbody {
  border: 1px solid #000;
}

.table-dynamic > tbody > tr {
  vertical-align: middle;
}

.table-dynamic > tbody > tr > td {
  border-bottom: 1px solid #000;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-dynamic.movements > tbody > tr > td {
  border-bottom: 1px solid #ccc;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 9px;
}

.table-dynamic.movements tbody tr:last-child td {
  border-bottom: 1px solid #000;
}

.table-dynamic.movements > tbody > tr > td.empty {
  font-size: 14px;
  text-align: center;
  background-color: #e8e8e8
}

.table-dynamic .table-title {
  text-align: left;
}

.v-pad-5 {
  padding: 5px 0;
}

.v-pad-10 {
  padding: 10px 0;
}

.w-qr {
  width: 170px;
}

.h-qr {
  height: 170px;
}
</style>