<template>
  <div>
    <simulator-form/>
  </div>
</template>

<script>
import SimulatorForm from "@components/simulators/express/SimulatorForm.vue";

export default {
  name: 'express-simulator',
  props: ['usuario'],
  components: {
    SimulatorForm
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {
    loading: function (newVal, oldVal) {
      if (newVal === true) {
        this.$vs.loading();
      } else {
        this.$vs.loading.close();
      }
    }
  }
}
</script>

