<template>
  <div class="simulator-express-resume">

    <div class="cell outline text-left" style="width: 400px;">
      <div class="flex">
        <div class="flex-1">Monto a financiar</div>
        <div class="col-md-6">{{ formatPrice(plans[0].finance.total_cost) }}</div>
      </div>
    </div>

    <table cellspacing="5">
      <tr>
        <td class="cell dark">Plan</td>
        <td v-for="plan in plans" class="cell orange">{{ plan.name }}</td>
      </tr>
      <tr v-if="plans[0].finance.financial_product_id === 1 || plans[0].finance.financial_product_id === 2">
        <td class="cell dark">Enganche</td>
        <td class="cell outline" v-for="plan in plans">
          {{ plan.finance.deposit_percentage * 100|toFixed }}%
        </td>
      </tr>
      <tr>
        <td class="cell dark">Meses</td>
        <td class="cell outline" v-for="plan in plans">{{ plan.finance.term }}</td>
      </tr>
      <tr>
        <td class="cell dark">Tasa Anual</td>
        <td class="cell outline" v-for="plan in plans">
          {{ plan.finance.annual_interest_percentage * 100|toFixed }}%
        </td>
      </tr>
      <tr v-if="plans[0].finance.financial_product_id === 1">
        <td class="cell dark">Pago Entrega</td>
        <td class="cell outline" v-for="plan in plans">
          ${{ formatPrice(plan.finance.symbolic_payment) }}
          <template v-if="plan.finance.delivery_term > 0">
            ({{ plan.finance.delivery_term }} Pagos)
          </template>
        </td>
      </tr>
      <tr>
        <td class="cell dark">Pago Mensual</td>
        <td class="cell outline" v-for="plan in plans">
          ${{ formatPrice(plan.finance.monthly_payment) }} ({{ plan.finance.term - plan.finance.delivery_term }} Pagos)
        </td>
      </tr>
      <tr>
        <td class="cell"></td>
        <td class="text-center" v-for="plan in plans">
          <vs-button
              icon-pack="feather"
              icon="icon-download-cloud"
              @click="showDetailedSimulator(plan)">
            Tabla Detallada
          </vs-button>
        </td>
      </tr>
    </table>


    <vs-popup
        :active.sync="showPlanSimulatorDialog"
        fullscreen
        title="SIMULACIÓN DE CRÉDITO"
    >
      <plan-details :name_cot="name_cot" :plan="planSelected"></plan-details>
    </vs-popup>
  </div>
</template>

<script>
import {formatPrice} from '@/filters.js'
import PlanDetails from "@components/simulators/express/plan-details.vue";

export default {
  components: {PlanDetails},
  props: ['plans', 'name_cot'],
  data: () => ({
    showPlanSimulatorDialog: false,
    planSelected: null
  }),
  filters: {
    toFixed(value) {
      return value.toFixed(2)
    }
  },
  methods: {
    formatPrice,
    showDetailedSimulator(plan) {
      this.planSelected = plan;
      this.showPlanSimulatorDialog = true;
    }
  },
}
</script>

<style scoped>
.btn {
  cursor: pointer;
}

.title {
  color: #28DE18;
  font-weight: bold;
}

.simulator-express-resume {
  margin: 0 1rem;
}

.cell {
  padding: 10px;
  margin: 6px;
  text-align: center;
  font-weight: bold;
}

.dark {
  background-color: rgba(var(--vs-rgblack), 1) !important;
  color: #FFF;
}

.orange {
  background-color: #28DE18;
  color: #FFF !important;
}

.outline {
  background-color: #fff;
  color: rgba(var(--vs-rgblack), 1) !important;
  font-weight: 600;
  border: 3px solid rgba(var(--vs-rgblack), 1) !important;
}
</style>
