<template>
  <div id="simulador-express">
    <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
              <h1 class="rg-big-title extrabold rg-title">Simulador <span class="orange">Express</span>.</h1>
          </div>
        </div>
    </vx-card>
    
    <express-simulator />
  </div>
</template>

<script>
import ExpressSimulator from '@components/ExpressSimulator'
export default {
  components: {
    ExpressSimulator
  }
}
</script>

<style scoped>
  .rg-title {
      font-size: 3rem !important;
  }
</style>